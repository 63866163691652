/*=========================================================================================
  File Name: moduleCountryMutations.js
  Description: Country Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  ADD_ITEM(state, item) {
    state.OffersAccepteds.unshift(item)
  },
  SET_ServiceRequests(state, ServiceRequests) {
    debugger;
    try {
     
      state.ServiceRequests = ServiceRequests;
      
    } catch (error) {
      console.error("Failed to parse ServiceRequests data JSON:", error);
      state.ServiceRequests = [];
    }
  },
  UPDATE_OffersAccepted(state, OffersAccepted) {
    debugger
    const OffersAcceptedIndex = state.OffersAccepteds.findIndex((p) => p.ID == OffersAccepted.Id)
    Object.assign(state.OffersAccepteds[OffersAcceptedIndex], OffersAccepted)
  },
  REMOVE_ITEM(state, itemId) {
      const ItemIndex = state.OffersAccepteds.findIndex((p) => p.Id == itemId)
      state.OffersAccepteds.splice(ItemIndex, 1)
  },
}
