/*=========================================================================================
  File Name: moduleCountryActions.js
  Description: Country Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios.js"

export default {
  // addItem({ commit }, item) {
  //   debugger;
  //   return new Promise((resolve, reject) => {
  //     axios.post("API/ServiceProviderRequest/Add", item)
  //       .then((response) => {
  //         commit('ADD_ITEM', Object.assign(item, { ID: response.data.data.ID }))
  //         resolve(response)
  //       })
  //       .catch((error) => { reject(error) })
  //   })
  // },

  fetchDataListItems({ commit }) {
    debugger;
    var Token={Authorization: 'Bearer ' + localStorage.accessToken};
    return new Promise((resolve, reject) => {
      axios.get("API/ServiceRequest/Index",{headers:Token})
        .then((response) => {
          commit('SET_ServiceRequests', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  // updateItem({ commit }, item) {
  //   return new Promise((resolve, reject) => {
  //     axios.put("API/ServiceProviderRequest/Update", item)
  //       .then((response) => {
  //         commit('UPDATE_ServiceProviderRequests', item)
  //         resolve(response)
  //       })
  //       .catch((error) => { reject(error) })
  //   })
  // },

  // GetItemByID({ commit }, itemid) {
  //   return new Promise((resolve, reject) => {
  //     axios.get("API/ServiceProviderRequest/GetById?Id=" + itemid)
  //       .then((response) => {
  //         commit('UPDATE_ServiceProviderRequest', response.data.data)
  //         resolve(response)
  //       })
  //       .catch((error) => { reject(error) })
  //   })
  // },

  // removeItem({ commit }, item) {
  //   var Token={Authorization: 'Bearer ' + localStorage.accessToken};
  //   return new Promise((resolve, reject) => {
  //     axios.delete("API/ServiceProviderRequest/Delete?Id="+item.Id,{headers:Token})
  //       .then((response) => {
  //         commit('REMOVE_ITEM', item.Id)
  //         resolve(response)
  //       })
  //       .catch((error) => { reject(error) })
  //   })
  // },
}
