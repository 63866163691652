/*=========================================================================================
  File Name: moduleLanguageState.js
  Description: Language Module State
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {
  ServiceRequests:[],
 // OutbreakCountries:[],
 ServiceRequest:{}
}
